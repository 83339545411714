<template>
    <div v-if="email">
        <div class="vx-row">
            <div class="vx-col w-1/2 mb-base">
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <vx-card title="Meta" :subtitle="email.code">
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-input
                                        class="w-full"
                                        type="number"
                                        v-model="email.order"
                                        label="Timeline order"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-textarea
                                        class="w-full"
                                        type="email"
                                        v-model="email.sent_when"
                                        label="Sent event details"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-textarea
                                        class="w-full"
                                        v-model="email.description"
                                        label="Description"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-textarea
                                        class="w-full"
                                        v-model="email.user_description"
                                        label="User Friendly Description"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-textarea
                                        class="w-full"
                                        v-model="email.notes"
                                        label="Notes"
                                    />
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-full ml-auto">
                                    <vs-button
                                        class="mr-3 mb-2"
                                        v-on:click="saveBack"
                                        >Save & Back</vs-button
                                    >
                                    <vs-button
                                        class="mr-3 mb-2"
                                        v-on:click="save"
                                        >Save</vs-button
                                    >
                                    <vs-button
                                        type="border"
                                        class="mb-2 vs-con-loading__container"
                                        v-on:click="show.sendTest = true"
                                        ref="loadableButton"
                                        >Send test email</vs-button
                                    >
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </div>
            </div>

            <div class="vx-col w-1/2 mb-base">
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <vx-card title="Subjects">
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-textarea
                                        class="w-full"
                                        v-model="email.sent_from_email_address"
                                        label="From Email Address"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-textarea
                                        class="w-full"
                                        v-model="email.title"
                                        label="Title"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-textarea
                                        class="w-full"
                                        v-model="email.subject"
                                        label="Subject"
                                    />
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </div>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col w-1/2 mb-base">
                <div class="row">
                    <div class="vx-col w-full mb-base">
                        <vx-card title="Template" class="showOverflow">
                            <div class="vx-row mb-6">
                                <div class="vx-col sm:w-1/4 w-full">
                                    <span>Base</span>
                                </div>
                                <div class="vx-col sm:w-3/4 w-full">
                                    <v-select
                                        v-if="baseOptions"
                                        class="w-full"
                                        :value="email.base"
                                        @input="setBase"
                                        :options="baseOptions"
                                        :reduce="(base) => base.id"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col sm:w-1/4 w-full">
                                    <span>Header</span>
                                </div>
                                <div class="vx-col sm:w-3/4 w-full">
                                    <v-select
                                        class="w-full"
                                        :value="email.header"
                                        @input="setHeader"
                                        :options="headerOptions"
                                        :reduce="(base) => base.id"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col sm:w-1/4 w-full">
                                    <span
                                        >Pre Footer (Advert, infographic
                                        etc)</span
                                    >
                                </div>
                                <div class="vx-col sm:w-3/4 w-full">
                                    <v-select
                                        class="w-full"
                                        :value="email.pre_footer"
                                        @input="setPreFooter"
                                        :options="prefooterOptions"
                                        :reduce="(base) => base.id"
                                    />
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col sm:w-1/4 w-full">
                                    <span>Footer</span>
                                </div>
                                <div class="vx-col sm:w-3/4 w-full">
                                    <v-select
                                        class="w-full"
                                        :value="email.footer"
                                        @input="setFooter"
                                        :options="footerOptions"
                                        :reduce="(base) => base.id"
                                    />
                                </div>
                            </div>
                        </vx-card>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <vx-card title="HTML Content" collapse-action>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <codemirror
                                        :options="cmOption"
                                        v-model="email.body"
                                    ></codemirror>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </div>
            </div>
            <div class="vx-col w-1/2 mb-base">
                <div class="vx-col w-full mb-base">
                    <EmailPreviewCard :data="emailPreview"></EmailPreviewCard>
                </div>
            </div>
        </div>
        <SendTestEmail
            :email="email"
            :show="show.sendTest"
            @close="show.sendTest = false"
        ></SendTestEmail>
    </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/xml/xml'
import 'codemirror/theme/monokai.css'
import 'codemirror/addon/lint/yaml-lint.js'
import 'codemirror/addon/scroll/simplescrollbars.css'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/lint/lint.js'

import { http } from '@/services'
import vSelect from 'vue-select'

import EmailPreviewCard from './Cards/Preview'
import SendTestEmail from './Modals/SendTest'

export default {
    name: 'EmailDetail',
    components: {
        'v-select': vSelect,
        codemirror,
        EmailPreviewCard,
        SendTestEmail,
    },
    created() {
        this.emailFetch(this.$route.params.pk)
    },
    mounted() {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    computed: {
        baseOptions() {
            return this.bases.map(function (template) {
                return {
                    id: template.id,
                    label: template.description,
                }
            })
        },
        headerOptions() {
            return this.headers.map(function (template) {
                return {
                    id: template.id,
                    label: template.description,
                }
            })
        },
        prefooterOptions() {
            return this.prefooters.map(function (template) {
                return {
                    id: template.id,
                    label: template.description,
                }
            })
        },
        footerOptions() {
            return this.footers.map(function (template) {
                return {
                    id: template.id,
                    label: template.description,
                }
            })
        },
        emailBasePreview() {
            var base = this.base.content
            if (this.header) {
                base = base.replace(
                    '{% if header %}{{ header|safe }}{% endif %}',
                    this.header.content
                )
            } else {
                base = base.replace(
                    '{% if header %}{{ header|safe }}{% endif %}',
                    ''
                )
            }
            if (this.prefooter) {
                base = base.replace(
                    '{% if pre_footer %}{{ pre_footer|safe }}{% endif %}',
                    this.prefooter.content
                )
            } else {
                base = base.replace(
                    '{% if pre_footer %}{{ pre_footer|safe }}{% endif %}',
                    ''
                )
            }
            if (this.footer) {
                base = base.replace(
                    '{% if footer %}{{ footer|safe }}{% endif %}',
                    this.footer.content
                )
            } else {
                base = base.replace(
                    '{% if footer %}{{ footer|safe }}{% endif %}',
                    ''
                )
            }
            return base
        },

        emailPreview() {
            if (this.base) {
                return this.emailBasePreview
                    .replace('{{ body|safe }}', this.email.body)
                    .replace('{% load tags %}', '')
            } else {
                return this.email.body
            }
        },
    },
    data() {
        return {
            email: null,
            bases: null,
            headers: null,
            prefooters: null,
            footers: null,
            base: null,
            header: null,
            prefooter: null,
            footer: null,
            backPath: null,
            sending: false,
            cmOption: {
                // codemirror options
                tabSize: 2,
                mode: 'text/html',
                lineNumbers: true,
                line: true,
                // theme: 'monokai',
                lineWrapping: true,
                spellcheck: true,
                scrollbarStyle: null,
                lint: true,
                foldGutter: true,
                gutters: [
                    'CodeMirror-lint-markers',
                    'CodeMirror-linenumbers',
                    'CodeMirror-foldgutter',
                ],
            },
            show: {
                sendTest: false,
            },
        }
    },
    methods: {
        emailFetch(pk) {
            http.get('email_detail', { params: { id: pk } })
                .then((response) => {
                    this.email = response.data['email']
                    this.bases = response.data['base_options']
                    this.headers = response.data['header_options']
                    this.prefooters = response.data['pre_footer_options']
                    this.footers = response.data['footer_options']
                    if (this.email.base) this.baseFetch()
                    if (this.email.header) this.headerFetch()
                    if (this.email.pre_footer) this.prefooterFetch()
                    if (this.email.footer) this.footerFetch()
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        async save() {
            http.patch('email_detail', this.email)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Email saved',
                        color: 'success',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-save',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-save',
                    })
                })
        },
        saveBack() {
            this.save().then((response) => this.back())
        },
        setBase(value) {
            if (value) {
                this.email.base = value
                this.baseFetch()
            } else {
                this.email.base = null
                this.base = null
            }
        },
        setHeader(value) {
            if (value) {
                this.email.header = value
                this.headerFetch()
            } else {
                this.email.header = null
                this.header = null
            }
        },
        setPreFooter(value) {
            if (value) {
                this.email.pre_footer = value
                this.prefooterFetch()
            } else {
                this.email.pre_footer = null
                this.prefooter = null
            }
        },
        setFooter(value) {
            if (value) {
                this.email.footer = value
                this.footerFetch()
            } else {
                this.email.footer = null
                this.footer = null
            }
        },
        back() {
            if (this.backPath) this.$router.push(this.backPath)
            else this.$router.push({ name: 'EmailsAll' })
        },
        baseFetch() {
            if (this.email && this.email.base) {
                http.get('email_base', { params: { id: this.email.base } })
                    .then((response) => {
                        this.base = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        headerFetch() {
            if (this.email && this.email.header) {
                http.get('email_header', { params: { id: this.email.header } })
                    .then((response) => {
                        this.header = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        prefooterFetch() {
            if (this.email && this.email.pre_footer) {
                http.get('email_pre_footer', {
                    params: { id: this.email.pre_footer },
                })
                    .then((response) => {
                        this.prefooter = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        footerFetch() {
            if (this.email && this.email.footer) {
                http.get('email_footer', { params: { id: this.email.footer } })
                    .then((response) => {
                        this.footer = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>

<style>
.CodeMirror {
    height: 984px;
}
.showOverflow {
    overflow: visible;
}
</style>
